import React, { useMemo } from 'react'
import styled from 'styled-components'

import { theme } from 'styled-tools'
import { MainTitle } from '../Typography'
import { canUseWebP } from '../Blocks/ImgixImg'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const InfoDiv = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	grid-area: 'assessoria';
`

const InfoTxt = styled.div`
	background-color: ${theme('colors.grayf8')};
	width: 40%;
	padding: 20px;

	@media (max-width: 767.98px) {
		width: 100%;
		padding: 20px 15px;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		width: 42%;
	}

	/* tinha no bootstrap */
	box-sizing: border-box;
`

// const InfoTitle = styled.h3`
// 	position: relative;
// 	font-size: 26px;
// 	color: ${theme('colors.black_fonts')};
// 	padding-bottom: 10px;

// 	&:after {
// 		content: '';
// 		width: 60px;
// 		height: 4px;
// 		background-color: ${theme('colors.primary')};
// 		position: absolute;
// 		bottom: 0;
// 		left: 0;

// 		@media (max-width: 767.98px) {
// 			width: 90px;
// 		}
// 	}
// `

const InfoP = styled.p`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin-block-start: 0;
	margin-block-end: 0;
	padding-top: 12px;
`

const InfoImg = styled.img`
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 60%;
	height: 365px;
	border-radius: 5px;
	object-fit: cover;

	@media (max-width: 767.98px) {
		height: unset;
		width: 100%;
		margin: 0;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		display: none;
		width: 55%;
		margin: 0 10px;
		height: unset;
	}
`

const InfoImgTablet = styled.img`
	display: none;

	@media (min-width: 768px) and (max-width: 991.98px) {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 60%;
		height: 365px;
		border-radius: 5px;
		object-fit: cover;
		width: 55%;
		margin: 0 10px;
		height: unset;
		display: block;
	}
`

export default function InfoMedicos({ itens }) {
	const strWepp = useMemo(() => (canUseWebP ? '?fm=webp&lossless=0&fit=crop&w=820&h=365&crop=faces' : ''), [])
	const strWeppTablet = useMemo(() => (canUseWebP ? '?fm=webp&lossless=0&fit=crop&crop=faces' : ''), [])
	return (
		<InfoDiv>
			<InfoTxt>
				<MainTitle>{itens.titulo_assessoria_medica}</MainTitle>
				<InfoP>{itens.assessoria_medica}</InfoP>
			</InfoTxt>
			<InfoImg src={`${imgixUrlOptimizerAuto(itens.assessoria_medica_imagem.imgix_url)}${strWepp}`} />
			<InfoImgTablet src={`${imgixUrlOptimizerAuto(itens.assessoria_medica_imagem.imgix_url)}${strWeppTablet}`} />
		</InfoDiv>
	)
}
