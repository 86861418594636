/**
 * Função para colocar dentro do sort(), ordenando através do metadata.data
 * (usado hoje nas noticias da home clientes)
 */
const order = (a, b) => {
	const dataA = new Date(a.metadata.data)
	const dataB = new Date(b.metadata.data)
	return dataB - dataA
}

export default order
