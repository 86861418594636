import React, { useState, useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import styled from 'styled-components'
import destaque1 from '@monorepo-amais/commons/images/destaque1.png'
import { theme } from 'styled-tools'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const CarouselCustom = styled.div`
	margin: 0 0 40px 0;
	@media (max-width: 1023px) {
		display: none;
	}

	/* background-color: ${theme('colors.black', '#000')}; */
	background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index:1;

	.react-multi-carousel-list {
		margin: 0;
		width: 100%;
		max-width: 100%;
		/* padding: 55px 0; */
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		height: 500px;

		.react-multi-carousel-track {
			z-index: 1;

			@media (max-width: 767.98px) {
				height: 100%;
			}
		}

		.mask {
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.8);
			z-index: 0;
		}

		.react-multiple-carousel__arrow {
			background: none;
			min-width: 60px;
			min-height: 60px;

			&.react-multiple-carousel__arrow--left {
				left: calc(8% + 1px);
			}

			&.react-multiple-carousel__arrow--right {
				right: calc(8% + 1px);
			}

			&:before {
				font-size: 42px;
				font-weight: 900;
			}

			&:focus {
				outline: none;
			}
		}

		.react-multi-carousel-dot-list {
			z-index: 1;
			bottom: 8%;

			.react-multi-carousel-dot {
				button {
					border: none;
					background-color: ${theme('colors.grayb3')};
					width: 8px;
					height: 8px;
				}

				&.react-multi-carousel-dot--active {
					button {
						background-color: ${theme('colors.primary')};
					}
				}
			}
		}
	}

	.react-multi-carousel-item {
		.carousel-txt {
			opacity: 0;
			transition: 0.1s;
		}

		.img-carousel {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);

			img {
				min-width: 280px;
				opacity: 0.4;
				height: 431px;
				object-fit: cover;
			}
		}
	}

	.react-multi-carousel-item--active {
		.carousel-txt {
			opacity: 1;
			height: 100%;
			/* transition-property: opacity height 0.5s;
			transition-duration: 0.5s;
			transition-timing-function: linear;
			transition-delay: 0.5s; */
		}

		.img-carousel {
			position: relative;
			left: 0;
			transform: translateX(0);

			img {
				opacity: 1;
				transition-property: width;
				transition-duration: 2s;
				transition-timing-function: linear;
				transition-delay: 1s;
				height: 431px;
				object-fit: cover;

				@media (max-width: 1279px) {
					width: 20vw;
				}
				@media (min-width: 1280px) {
					min-width: 310px;
				}
			}
		}
	}

	.my-node-enter {
		opacity: 0;
	}
	.my-node-enter-active {
		opacity: 1;
		transition: opacity 200ms;
	}
	.my-node-exit {
		opacity: 1;
	}
	.my-node-exit-active {
		opacity: 0;
		transition: opacity 200ms;
	}
`

const CarouselItem = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 0 10px;
	min-height: 300px;
	color: ${theme('colors.white')};

	@media (max-width: 767.98px) {
		display: block;
	}
`

const CarouselTxt = styled.div`
	padding: 10px;
`

const Subtitle = styled.p`
	color: ${theme('colors.primary')};
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 1px;
	margin-bottom: 16px;

	@media (max-width: 767.98px) {
		font-size: 24px;
		margin-bottom: 16px;
		font-weight: bold;
		position: relative;
		padding-bottom: 20px;

		&:after {
			content: '';
			width: 75px;
			height: 4px;
			background-color: ${theme('colors.primary')};
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
`

const Title = styled.h3`
	color: ${theme('colors.white', '#fff')};
	max-width: 180px;
	line-height: 1.7;
	position: relative;
	padding-bottom: 18px;
	margin-bottom: 24px;

	&:after {
		content: '';
		width: 75px;
		height: 4px;
		background-color: ${theme('colors.primary')};
		position: absolute;
		bottom: 0;
		left: 0;
	}

	@media (max-width: 767.98px) {
		padding-bottom: 0;
		margin-bottom: 10px;
		font-size: 22px;

		&:after {
			display: none;
		}
	}
`

const Description = styled.p`
	color: ${theme('colors.white', '#fff')};
	margin-bottom: 40px;

	@media (max-width: 767.98px) {
		margin-bottom: 30px;
		font-size: 14px;
	}
`

const BtnLink = styled.a`
	text-decoration: none;
`

const Btn = styled.button`
	border: none;
	background-color: ${theme('colors.white', '#fff')};
	border-radius: 100px;
	font-size: 14px;
	padding: 12px 45px;
	font-weight: 600;
	color: ${theme('colors.black33')};
	&:hover {
		color: ${theme('colors.black33')};
		text-decoration: none;
		background-color: ${theme('colors.grayf1')};
	}

	@media (max-width: 767.98px) {
		padding: 12px 50px;
	}
`

const Mask = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: 1;
`

const CarouselDestaques = ({ data }) => {
	const [img, setImg] = useState()

	useEffect(() => {
		setImg(destaque1)
	}, [])

	const ImagemDestaques = {
		backgroundImage: `url(${img})`
	}

	const listaDestaques = data.metadata.destaques

	useEffect(() => {
		const backButton = document.querySelector('[aria-label="Go to previous slide"]') || {}
		const nextButton = document.querySelector('[aria-label="Go to next slide"]') || {}
		if (!backButton.id) backButton.id = 'featured-back-button'
		if (!nextButton.id) nextButton.id = 'featured-next-button'
	})

	return (
		<CarouselCustom style={ImagemDestaques}>
			<Mask></Mask>
			<Carousel
				ssr
				additionalTransfrom={0}
				arrows
				autoPlaySpeed={3000}
				centerMode
				draggable
				focusOnSelect={false}
				infinite
				keyBoardControl
				minimumTouchDrag={80}
				responsive={{
					desktop: {
						breakpoint: {
							max: 3000,
							min: 1024
						},
						items: 1
					}
				}}
				showDots={true}
				sliderClass=''
				slidesToSlide={1}
				swipeable
			>
				{listaDestaques &&
					listaDestaques.map((item, index) => (
						<CarouselItem key={`featured-carousel-item-${index}`}>
							<CarouselTxt className='carousel-txt'>
								<Subtitle>Destaques</Subtitle>
								<Title id={`title-featured-carousel-${index}`}>{item.titulo}</Title>
								<Description>{item.descricao}</Description>
								{item.cta_link && (
									<BtnLink id={`button-featured-carousel-${index}`} href={item.cta_link} target='_blank'>
										<Btn>{item.cta}</Btn>
									</BtnLink>
								)}
							</CarouselTxt>
							<div className='img-carousel'>
								<img src={imgixUrlOptimizerAuto(item.imagem.imgix_url)} alt={item.titulo} />
							</div>
						</CarouselItem>
					))}
			</Carousel>
		</CarouselCustom>
	)
}

export default CarouselDestaques
