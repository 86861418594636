/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Link } from 'gatsby'
import Calendar from '@monorepo-amais/commons/assets/img/calendar.png'
import Slider from 'react-slick'
import { Button as Btn } from '../Buttons'
// import { ImgixImg } from '../Blocks'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const ContainerCustom = styled.div`
	max-width: calc(647px * 2);
	margin: 0 auto 40px auto;

	@media (max-width: 767.98px) {
		padding: 20px 0px 10px 20px;
	}

	.slick-dots li button {
		&:before {
			color: ${props => props.theme.colors.mainColor};
			font-size: 10px;
		}
	}
`

const Cards = styled.div`
	border: 1px solid ${props => props.theme.colors.graye2};
	background-color: ${theme('colors.white')};
	color: ${props => props.theme.colors.black};
	margin: 0 auto;
	display: block;
	min-height: 382px;
	width: 100%;
	padding: 0 0 10px;
	border-radius: 6px;
	@media (max-width: 991.98px) {
		border: none;
		min-height: 492px;
	}
	@media (min-width: 992px) {
		width: 95% !important;
	}
`

const CardCustom = styled.div`
	border: none;
	width: 100% !important;
`

const CardTitle = styled.h3`
	margin: 0;
	position: relative;
	font-weight: bold;
	line-height: 1.32;
	text-align: left;
	margin-bottom: 15px;
	font-size: 40px;
	color: #464646;
	font-weight: bold;
	// @media (min-width: 1200px) {
	// 	width: 88%;
	// }
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 90px;
		height: 5px;
		background: ${props => props.theme.colors.primary};

		@media (max-width: 767.98px) {
			font-size: 20px;
		}
	}
`
const CardList = styled.div`
	padding-top: 20px;
	// width: 88%;
	padding: 0;
	margin: 0 auto;

	.slick-track {
		margin: 0;
		display: flex;
	}

	.slick-slide {
		width: 25%;
	}

	@media (max-width: 1023.98px) {
		width: 100%;

		.slick-slide {
			> div {
				margin: 0 10px 0 0;
				border: 1px solid ${props => props.theme.colors.graye2};
			}
		}
		.slick-arrow {
			display: none !important;
		}
	}

	@media (min-width: 1024px) {
		.slick-track {
			width: unset !important;
			justify-content: space-between;
		}
		.slick-slide {
			width: 24% !important;
		}
	}
`

const CardBody = styled.div`
	padding: 10px 10px 0;
	height: 100%;
	display: block;
`
const CardImage = styled.img`
	height: 184px;
	border: none;
	width: 100% !important;
	object-fit: cover;
	.card-img-top {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
`
const Title = styled.p`
	margin: 0;
	position: relative;
	font-size: 22px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.32;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};
	overflow: hidden;
	width: 280px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	@media (max-width: 991.98px) {
		font-size: 16px;
	}

	@media (max-width: 767.98px) {
		margin-top: 8px;
		width: 220px;
		-webkit-line-clamp: 2;
	}
`
const TitleSpan = styled.span`
	display: block;
	position: relative;
	bottom: -10px;
	left: 0;
	width: 90px;
	height: 5px;
	background: ${props => props.theme.colors.primary};
	@media (max-width: 767.98px) {
		height: 4px;
		left: 1px;
	}
`
const Text = styled.p`
	font-size: 16px;
	line-height: 1.19;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin-top: 25px;
	@media (max-width: 767.98px) {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 8;
		-webkit-box-orient: vertical;
	}
`

const CardView = styled(Link)`
	font-size: 14px;
	font-weight: bold;
	line-height: 1.3;
	text-align: right;
	color: ${props => props.theme.colors.primary};
	text-decoration: none;

	@media (max-width: 374.98px) {
		font-size: 12px;
	}

	@media (min-width: 375px) and (max-width: 413.98px) {
		font-size: 16px;
	}

	@media (min-width: 414px) and (max-width: 767.98px) {
		font-size: 18px;
	}

	@media (min-width: 1024px) and (max-width: 1199.98px) {
		font-size: 11px;
	}

	@media (min-width: 1200px) {
		font-size: 14px;
		text-align: left;
	}
`
const PData = styled.p`
	font-size: 14px;
	font-weight: bold;
	line-height: 1.3;
	text-align: left;
	color: #707070;
	margin: 0;

	@media (max-width: 374.98px) {
		font-size: 13px;
	}

	@media (min-width: 375px) and (max-width: 413.98px) {
		font-size: 16px;
	}

	@media (min-width: 414px) and (max-width: 767.98px) {
		font-size: 18px;
	}

	@media (min-width: 1024px) and (max-width: 1199.98px) {
		font-size: 11px;
	}

	@media (min-width: 1200px) {
		font-size: 14px;
	}
`
const RodapeCard = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;
	@media (max-width: 767.98px) {
		margin-bottom: 10px;
	}
	@media (min-width: 768px) and (max-width: 991.98px) {
		margin-bottom: 10px;
	}
	@media (min-width: 992px) and (max-width: 1199.98px) {
		margin-bottom: 10px;
	}
`
const Hr = styled.hr`
	display: block;
	height: 1px;
	border: none;
	background-color: ${props => props.theme.colors.graye2};
	&:last-child {
		display: none;
	}
`

const Icon = styled.img`
	margin-right: 0.6rem;
`

const CardsTitle = styled.div`
	min-height: 272px;
	@media (max-width: 767.98px) {
		min-height: 235px;
	}
`
const ButtonDiv = styled.div`
	text-align: right;
	width: 270.6px;
	height: 64px;
	margin: 30px 12px 0 auto;
	@media (max-width: 767.98px) {
		padding-right: 20px;
	}
	@media (max-width: 1024px) {
		width: 90%;
		height: 58px;
		margin: 0 auto;
	}
`
const Button = styled(Btn)`
	padding: 15px 35px;
	width: auto;
	height: auto;

	@media (max-width: 1024px) {
		width: 100%;
		margin-top: 20px;
	}
`

const TextButton = styled.span`
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.17;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
`
const NoticiasLink = styled(Link)`
	text-decoration: none;
`

const FlexIconData = styled.div`
	display: flex;
`

const settings = {
	className: 'center',
	centerMode: false,
	infinite: false,
	slidesToShow: 4,
	speed: 500,
	centerPadding: '20px',
	responsive: [
		{
			breakpoint: 1023,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				centerMode: true,
				infinite: true,
				centerPadding: 25
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
				centerMode: true,
				infinite: true,
				centerPadding: 25
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				centerMode: true,
				infinite: true,
				centerPadding: 25
			}
		}
	]
}

const cardsNoticias = ({ data }) => {
	const [isRendered, setIsRendered] = useState(false)

	const cardNoticia = data.map((item, index) => {
		const date = item.metadata.data.split('-')
		const dateString = date.length === 3 ? `${date[2]}/${date[1]}/${date[0]}` : ''
		return (
			<Cards key={item.slug}>
				<CardCustom id={`news-card-${index}`}>
					<CardImage src={imgixUrlOptimizerAuto(item.metadata.thumbnail.imgix_url)} h={184} />
					<CardBody>
						<CardsTitle>
							<Title id={`title-home-news-card-${index}`}>{item.title}</Title>
							<TitleSpan />
							<Text>{item.metadata.pequena_descricao}</Text>
						</CardsTitle>
						<Hr />
						<RodapeCard>
							<FlexIconData>
								<Icon src={Calendar}></Icon>
								<PData>{dateString}</PData>
							</FlexIconData>
							<CardView to={`/noticias/${item.slug}`}>Leia notícia completa</CardView>
						</RodapeCard>
					</CardBody>
				</CardCustom>
			</Cards>
		)
	})

	useEffect(() => {
		setTimeout(() => {
			setIsRendered(true)
		}, 200)
	}, [])

	return (
		<>
			<ContainerCustom>
				<CardTitle>Notícias</CardTitle>

				<CardList>
					{isRendered && <Slider {...settings}>{cardNoticia}</Slider>}
					<ButtonDiv>
						<NoticiasLink id='button-all-news' to='/noticias/'>
							<Button>
								<TextButton>Ver todas as notícias</TextButton>
							</Button>
						</NoticiasLink>
					</ButtonDiv>
				</CardList>
			</ContainerCustom>
		</>
	)
}

export default cardsNoticias
