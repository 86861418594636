import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button } from '../Buttons'

import { theme } from 'styled-tools'

// import Telefone from '../Icons/Telefone'
import { MainTitle } from '../Typography'
// import { ImgixImg } from '../Blocks'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	grid-gap: 22px;

	padding: 40px 10px;

	max-width: calc(647px * 2); /* tamanho da imagem x2 */

	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
	}
`

const Subtitle = styled.h4`
	margin: 0;
	margin-top: 10px;
	font-size: 26px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
`

const Text = styled.div`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};

	margin-block-start: 0;
	margin-block-end: 0;
`

const Image = styled.img`
	max-width: 647px;
	width: 100%;
`

const TextWrapper = styled.div`
	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		max-width: 647px;
		width: 100%;
		margin: 10px auto;
	}

	color: ${theme('colors.gray66')};
`

const ButtonText = styled.span`
	/* font-family: FSLola; */
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.17;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
`

const ButtonStyled = styled(Button)`
	width: auto;
	height: auto;
	padding: 15px 35px;
`

const TelephoneFlex = styled.div`
	display: flex;
	align-items: center;
`

const LinkDecoration = styled(Link)`
	text-decoration: none;
`

const ADecoration = styled.a`
	text-decoration: none;
`

export default function AtendimentoMovel({ campanha }) {
	const campanhaButton = (
		<ButtonStyled id='button-mobile-attendance'>
			<ButtonText>{campanha.metadata.campanha_botao}</ButtonText>
		</ButtonStyled>
	)

	const linkButton = campanha.metadata.campanha_link.includes('http') ? (
		<ADecoration href={campanha.metadata.campanha_link} target={'_blank'}>
			{campanhaButton}
		</ADecoration>
	) : (
		<LinkDecoration to={`/${campanha.metadata.campanha_link}/`}>{campanhaButton}</LinkDecoration>
	)

	return (
		<Wrapper>
			<Grid>
				{campanha && (
					<TextWrapper>
						<MainTitle>{campanha.metadata.campanha_titulo}</MainTitle>
						<Subtitle>{campanha.metadata.campanha_subtitulo}</Subtitle>
						<Text dangerouslySetInnerHTML={{ __html: campanha.metadata.campanha_texto }} />
						<TelephoneFlex>{linkButton}</TelephoneFlex>
					</TextWrapper>
				)}
				<Image
					src={imgixUrlOptimizerAuto(campanha.metadata.campanha_imagem.imgix_url)}
					alt='Atendimento móvel alt text'
					w={647}
				/>
			</Grid>
		</Wrapper>
	)
}
