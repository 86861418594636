import React, { useRef } from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import styled from 'styled-components'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import { FlexDiv } from '../Blocks'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const AliceCarouselCustom = styled.div`
	> .alice-carousel {
		.alice-carousel__dots {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			margin-bottom: 10px;

			.alice-carousel__dots-item.__active {
				background-color: ${props => props.theme.colors.primary};
			}

			.alice-carousel__dots-item {
				border: none;
				background: #b3b4b3;
				width: 12px;
				height: 12px;

				@media (max-width: 767.98px) {
					width: 8px;
					height: 8px;
				}
			}
		}

		.alice-carousel__next-btn,
		.alice-carousel__prev-btn {
			display: none;
		}

		@media (max-width: 991.98px) {
			position: relative;

			.alice-carousel__stage-item img {
				max-width: 420px;
			}
		}

		@media (min-width: 992px) {
			height: ${({ height }) => `${height}px`};
			margin-top: -26px;
		}
	}
`

const CustomItem = styled.div`
	cursor: ${props => props.cursor};
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: ${({ height }) => `${height}px`};
	background-image: ${props => `url(${props.backgroundImage})`};
	background-position: center center;

	@media (max-width: 1023.98px) {
		background-image: ${props => `url(${props.mobileBackgroundImage})`};
		height: 380px;
	}

	@media (max-width: 768px) {
		background-image: ${props => `url(${props.mobileBackgroundImage})`};
		height: 480px;
	}

	@media (max-width: 425px) {
		background-image: ${props => `url(${props.mobileBackgroundImage})`};
		height: 270px;
	}

	@media (max-width: 320px) {
		background-image: ${props => `url(${props.mobileBackgroundImage})`};
		height: 230px;
	}
`

const Back = styled(IoIosArrowBack)`
	height: 5%;
	width: 5%;
	cursor: pointer;
	position: absolute;
	top: 0;
	margin-top: 20%;

	@media (max-width: 1124px) {
		margin-top: 30%;
	}
	@media (max-width: 1023px) {
		visibility: hidden;
		margin-top: 50%;
	}
	@media (max-width: 992px) {
		visibility: hidden;
		height: 8%;
		width: 8%;
		margin-top: 60%;
	}
	@media (max-width: 768px) {
		visibility: hidden;
		margin-top: 70%;
	}
	@media (max-width: 630px) {
		visibility: hidden;
		height: 10%;
		width: 10%;
		margin-top: 75%;
	}
	@media (max-width: 560px) {
		visibility: hidden;
		margin-top: 90%;
	}
	@media (max-width: 470px) {
		visibility: hidden;
		height: 12%;
		width: 12%;
		margin-top: 110%;
	}
	@media (max-width: 400px) {
		visibility: hidden;
		margin-top: 130%;
	}
	@media (max-width: 375px) {
		visibility: hidden;
		margin-top: 150%;
	}
`

const Forward = styled(IoIosArrowForward)`
	height: 5%;
	width: 5%;
	cursor: pointer;
	position: absolute;
	top: 0;
	margin-top: 20%;
	right: 0;

	@media (max-width: 1124px) {
		margin-top: 30%;
	}
	@media (max-width: 1023px) {
		visibility: hidden;
		margin-top: 50%;
	}
	@media (max-width: 992px) {
		visibility: hidden;
		height: 8%;
		width: 8%;
		margin-top: 60%;
	}
	@media (max-width: 768px) {
		visibility: hidden;
		margin-top: 70%;
	}
	@media (max-width: 630px) {
		visibility: hidden;
		height: 10%;
		width: 10%;
		margin-top: 75%;
	}
	@media (max-width: 560px) {
		visibility: hidden;
		margin-top: 90%;
	}
	@media (max-width: 470px) {
		visibility: hidden;
		height: 12%;
		width: 12%;
		margin-top: 110%;
	}
	@media (max-width: 400px) {
		visibility: hidden;
		margin-top: 130%;
	}
	@media (max-width: 375px) {
		visibility: hidden;
		margin-top: 150%;
	}
`

const CustomFlex = styled(FlexDiv)`
	opacity: 0.3;
	height: auto;
	width: 100%;
`

const CarouselBanner = ({ data }) => {
	const carousel = useRef(null)

	const handleBannerClick = url => {
		if (url) {
			if (typeof window !== 'undefined') {
				window.dataLayer.push({
					event: 'click',
					url_destino: url,
					'Click URL': url
				})
				window.open(url, url.includes('http') ? '_blank' : '_self')
			}
		}
	}

	const heightRef = React.useRef()
	heightRef.current = 455
	React.useLayoutEffect(() => {
		heightRef.current = typeof window !== 'undefined' ? window.innerHeight * 0.6 : heightRef.current
	}, [])

	const listaBanner = data.metadata.carrossel_banner.carrossel_banner_imagens

	const handleOnDragStart = e => e.preventDefault()
	return (
		<AliceCarouselCustom height={heightRef.current} className='carousel-banner'>
			<AliceCarousel ref={carousel} mouseDragEnabled autoPlay={true} autoPlayInterval={5000}>
				{/* TODO: USAR MAP e styled-components */}
				{listaBanner &&
					listaBanner.map((item, index) => (
						<CustomItem
							onClick={() => handleBannerClick(item.link)}
							cursor={item.link ? 'pointer' : 'auto'}
							key={index}
							className='custom-item'
							mobileBackgroundImage={imgixUrlOptimizerAuto(item.imagem_mobile.imgix_url)}
							backgroundImage={imgixUrlOptimizerAuto(item.imagem.imgix_url)}
							onDragStart={handleOnDragStart}
							height={heightRef.current}
						/>
					))}
			</AliceCarousel>
			<CustomFlex justify='space-between'>
				<Back onClick={() => carousel.current.slidePrev()} />
				<Forward onClick={() => carousel.current.slideNext()} />
			</CustomFlex>
		</AliceCarouselCustom>
	)
}

export default CarouselBanner
