/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// import { calcRadius } from '@monorepo-amais/commons/utils/calcs'
// import { calcRadius, getGeolocation } from '@monorepo-amais/commons/utils/calcs'
import { Link } from 'gatsby'
import { MainTitle } from '../Typography'
import THUMBNAIL_PADRAO from '@monorepo-amais/commons/images/thumbnail_padrao.png'
import { theme } from 'styled-tools'
import { Button as Btn } from '../Buttons'

// import { ImgixImg } from '@monorepo-amais/commons/components/Blocks'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: auto;
	background-color: ${theme('colors.grayf8')};
`

const WrapperSmallLayout = styled.div`
	height: auto;
	background-color: ${theme('colors.grayf8')};
`

const SmallLayoutContent = styled.div`
	max-width: calc(647px * 2);
	margin: 0 auto 40px auto;
`

const ContainerUnidadePrincipal = styled.div`
	margin: 20px 15px 30px 0;

	@media (max-width: 1024px) {
		margin: 20px 0px 30px 0;
	}
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	grid-gap: 22px;

	padding: 40px 10px;

	max-width: calc(647px * 2); /* tamanho da imagem x2 */
	width: 91.25%;
	height: 100%;

	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		padding: 0px 10px;
	}
`

const UnidadePrincipal = styled.div`
	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: 0 auto;
	}
`

const UnidadesDireita = styled.div`
	width: 100%;
	margin: 91px auto 0px;

	@media (max-width: 1024px) {
		margin: 20px auto 0px;
		text-align: center;
	}
`

const MainImage = styled.img`
	max-height: 420px;
	object-fit: cover;
	width: 100%;
	border-radius: 5px;
`

const UnidadeTitulo = styled.p`
	font-size: 20px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.black33')};
	margin: 8px 0px;
`

const UnidadeSubtitulo = styled.p`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.13;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.gray66')};
	margin: 0px;
`

const ListaUnidades = styled.div`
	@media (min-width: 1025px) {
		display: inline-grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto;
		grid-gap: 20px;
	}
	grid-template-columns: 1fr 1fr;
	grid-gap: 2%;
	display: grid;
	${UnidadeTitulo} {
		max-height: 2.6em;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	${UnidadeSubtitulo} {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
`

const ListaUnidadesSmallLayout = styled.div`
	margin: 30px 0 0;
	@media (min-width: 1025px) {
		display: inline-grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: auto;
		grid-gap: 20px;
		margin: 30px auto 0;
		width: 100%;
	}
	grid-template-columns: 1fr 1fr;
	grid-gap: 2%;
	display: grid;
	${UnidadeTitulo} {
		max-height: 2.6em;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	${UnidadeSubtitulo} {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
`

const UnidadeDistancia = styled.p`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.primary')};
	margin: 0px;
	margin: 5px 0px;
`

const Unidade = styled.div`
	width: ${props => (props.smallLayout ? 'auto' : '200px')};
	display: inline-block;
	vertical-align: top;

	@media (max-width: 1024px) {
		width: 48%;
		margin: 0px 6px 20px 6px;
	}

	@media (max-width: 768px) {
		width: calc(100% - 12px);
		margin: 0px 6px 20px 6px;
	}
`

const UnidadeImage = styled.img`
	object-fit: fill;
	border-radius: 5px;

	height: 100%;
	width: 100%;
`

const ButtonDiv = styled.div`
	text-align: right;
	width: 270.6px;
	height: 64px;
	margin: 30px 0 0 auto;
	@media (max-width: 1024px) {
		width: 90%;
		height: 58px;
		margin: 20px auto 40px;
	}
`

const Button = styled(Btn)`
	padding: 15px 35px;
	width: auto;
	height: auto;

	@media (max-width: 1024px) {
		width: 100%;
	}
`

const TextButton = styled.span`
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.17;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
`

const UnidadePrincipalTitulo = styled.p`
	font-size: 18px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${theme('colors.white')};
	margin: 0px 0px 8px;
`

const UnidadePrincipalEndereco = styled.p`
	font-size: 15px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.13;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.white')};
	margin: 0px 0px 8px;
`

const UnidadePrincipalDiv = styled.div`
	position: relative;
	text-align: left;
	color: ${theme('colors.white')};
`

const UnidadePrincipalInfos = styled.div`
	position: absolute;
	bottom: 23px;
	left: 16px;
`

const UnidadePrincipalDistBox = styled.div`
	border-radius: 4px;
	background-color: ${theme('colors.primary')};
	width: fit-content;
	padding: 2px 12px;
	font-size: 15px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.white')};
`

const UnidadeLink = styled(Link)`
	text-decoration: none;
`

const UnidadeSecLink = styled(Link)`
	@media (max-width: 1024px) {
		display: none;
	}
`

const MaskCardUnidadeDestaqueDiv = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 35%;
	background: rgba(0, 0, 0, 0.2);
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;

	@media (max-width: 767.98px) {
		width: 100%;
		height: 50%;
	}
`

const ContainerImg = styled.div`
	height: 110px;
	overflow: hidden;

	@media (min-width: 767.98px) {
		height: 150px;
	}

	@media (min-width: 1248px) {
		height: 132px;
	}
`

const Unidades = ({ data }) => {
	// const [location, setLocation] = useState()
	const [units, setUnits] = useState(
		data.edges.map(({ node }) => ({
			id: node.id,
			...node
		}))
	)
	//FLAG PRA DEFINIR QUAL LAYOUT UTILIZAR
	const smallLayout = units.length <= 4
	// if (!location) {
	// 	getGeolocation(setLocation)
	// }
	useEffect(() => {
		// if (location) {
		setUnits(
			data.edges
				.map(({ node }) => ({
					id: node.id,
					// distance: calcRadius({ lat: node.latitude, lon: node.longitude, location }),
					...node
				}))
				.sort((a, b) => a.distance - b.distance)
		)
		// }
	}, [data.edges, typeof navigator !== 'undefined'])

	const arrayUnidades = []
	let unidadePrincipal = ''

	if (!smallLayout) {
		let distancia = ''
		if (units[0].distance !== undefined) {
			let unidDist = ''

			unidDist =
				units[0].distance < 1 ? `${(units[0].distance * 1000).toFixed(0)} metros` : `${units[0].distance.toFixed(0)} km`

			distancia = <UnidadePrincipalDistBox>{unidDist}</UnidadePrincipalDistBox>
		}
		const linkUnidade = '/unidades/' + units[0].slug
		unidadePrincipal = (
			<ContainerUnidadePrincipal>
				<UnidadeLink id='home-featured-unit' to={`${linkUnidade}`}>
					<UnidadePrincipalDiv>
						{units[0].thumbnail && units[0].thumbnail.imagem.imgix_url && (
							<MainImage src={`${imgixUrlOptimizerAuto(units[0].thumbnail.imagem.imgix_url, 621)}`} alt='Unidade' />
						)}
						{(!units[0].thumbnail || !units[0].thumbnail.imagem.imgix_url) && (
							<MainImage src={`${THUMBNAIL_PADRAO}`} alt='Unidade' w={621} />
						)}
						<MaskCardUnidadeDestaqueDiv />
						<UnidadePrincipalInfos>
							<UnidadePrincipalTitulo>{units[0].nome}</UnidadePrincipalTitulo>
							<UnidadePrincipalEndereco>{units[0].endereco}</UnidadePrincipalEndereco>
							{distancia}
						</UnidadePrincipalInfos>
					</UnidadePrincipalDiv>
				</UnidadeLink>
			</ContainerUnidadePrincipal>
		)
	}

	const startUnit = smallLayout ? 0 : 1

	for (let i = startUnit; i < units.length && i < 7; i++) {
		const itemDistance = units[i].distance
		let distancia = ''
		if (itemDistance !== undefined) {
			let unidDist = ''

			unidDist = itemDistance < 1 ? `${(itemDistance * 1000).toFixed(0)} metros` : `${itemDistance.toFixed(0)} km`
			distancia = <UnidadeDistancia id={`home-unit-distance-${i}`}>{unidDist}</UnidadeDistancia>
		}
		const linkUnidade = '/unidades/' + units[i].slug

		const unitContent = (
			<Unidade smallLayout>
				{units[i].thumbnail && units[i].thumbnail.imagem.imgix_url && (
					<ContainerImg>
						<UnidadeImage
							smallLayout
							src={`${imgixUrlOptimizerAuto(units[i].thumbnail.imagem.imgix_url, 199)}`}
							alt='Unidade'
						/>
					</ContainerImg>
				)}
				{(!units[i].thumbnail || !units[i].thumbnail.imagem.imgix_url) && (
					<ContainerImg>
						<UnidadeImage smallLayout src={`${THUMBNAIL_PADRAO}`} alt='Unidade' h={150} />
					</ContainerImg>
				)}
				<UnidadeTitulo>{units[i].nome}</UnidadeTitulo>
				<UnidadeSubtitulo>{units[i].endereco}</UnidadeSubtitulo>
				{distancia}
			</Unidade>
		)

		if (i < 3 || smallLayout) {
			arrayUnidades.push(
				<UnidadeLink id={`home-list-unit-${i}`} to={`${linkUnidade}`}>
					{unitContent}
				</UnidadeLink>
			)
		} else {
			arrayUnidades.push(<UnidadeSecLink to={`${linkUnidade}`}>{unitContent}</UnidadeSecLink>)
		}
	}

	const title = <MainTitle>Unidades</MainTitle>

	return (
		<>
			{!smallLayout && (
				<Wrapper>
					<Grid>
						<UnidadePrincipal id='container-featured-unit'>
							{title}
							{unidadePrincipal}
						</UnidadePrincipal>
						<UnidadesDireita id='container-unit-list'>
							<ListaUnidades id='unit-list'>{arrayUnidades}</ListaUnidades>
							<ButtonDiv>
								<UnidadeLink id='home-all-units' to='/unidades/'>
									<Button data-testid='ButtonGoToList'>
										<TextButton>Ver todas unidades</TextButton>
									</Button>
								</UnidadeLink>
							</ButtonDiv>
						</UnidadesDireita>
					</Grid>
				</Wrapper>
			)}
			{smallLayout && (
				<WrapperSmallLayout>
					<SmallLayoutContent>
						{title}
						<ListaUnidadesSmallLayout data-testid='UnitSmallList'>{arrayUnidades}</ListaUnidadesSmallLayout>
					</SmallLayoutContent>
				</WrapperSmallLayout>
			)}
		</>
	)
}

export default Unidades
