import React, { useState, useEffect } from 'react'

import Slider from 'react-slick'
import styled from 'styled-components'

import { theme } from 'styled-tools'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const Carrossel = styled.div`
	margin: 0 0 40px 0;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	z-index: 1;

	@media (min-width: 1024px) {
		display: none;
	}

	.slick-initialized {
		margin: 0;
		width: 100%;
		max-width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;

		.slick-list {
			padding: 0px 50px;
		}

		.slick-track {
			width: 2200px;
			opacity: 1;
			transform: translate3d(-550px, 0px, 0px);
			/* background: #000; */
			padding: 40px 0;
		}

		.slick-cloned {
			width: 275px;
		}

		.slick-slide {
			float: left;
			height: 100%;
			min-height: 1px;
			outline: none;
			width: 275px;

			&.slick-active {
				.slick-text {
					opacity: 1;
					transition-property: opacity 0.4s;
					transition-duration: 0.4s;
					transition-timing-function: ease;
					transition-delay: 0.4s;
				}
			}

			.slick-text {
				opacity: 0;
			}
		}

		.slick-arrow {
			display: none !important;
			position: relative;

			/* O css abaixo não está funcionando */
			/* .slick-next {
				button {
					&:before {
						display: none;
					}
				}
			}

			.slick-prev {
				button {
					&:before {
						display: none;
					}
				}
			} */
		}

		.slick-dots {
			position: absolute;
			bottom: 0;
			display: block;
			width: 100%;
			padding: 0 0 5px 0;
			margin: 0;
			list-style: none;
			text-align: center;

			li button {
				position: relative;

				&:before {
					color: ${theme('colors.grayb3')};
					font-size: 9px;
				}
			}

			.slick-active button {
				&:before {
					color: ${theme('colors.primary')};
				}
			}
		}
	}
`

const Img = styled.img`
	margin: 0 auto;
	margin-top: auto;
	width: 90%;
	height: 234px;
	object-fit: cover;
`

const CarouselItem = styled.div`
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 0 10px;
	min-height: 580px;
	/* Tem que forçar o componente a ser flex aqui, o slick transforma ele automaticamente para inline-block
	e portanto as imagens ficam desiguais quando o texto é desigual
	A imagem tem um margin-top auto para ficar na mesma altura */
	display: flex !important;
	flex-direction: column;
	padding: 0;

	@media (max-width: 991.98px) {
	}
`

const CarouselTxt = styled.div`
	height: auto;
	margin-top: -20px;
	padding: 20px 35px;
`

const Subtitle = styled.p`
	color: ${theme('colors.primary')};
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
	position: relative;
	margin: 0 0 10px;
	padding-bottom: 15px;
	font-size: 26px;

	&:after {
		content: '';
		width: 75px;
		height: 4px;
		background-color: ${theme('colors.primary')};
		position: absolute;
		bottom: 0;
		left: 0;
	}
`

const Title = styled.p`
	color: ${theme('colors.white', '#fff')};
	line-height: 1.7;
	position: relative;
	padding-bottom: 18px;
	margin: 0 0 10px;

	&:after {
		content: '';
		width: 75px;
		height: 4px;
		background-color: ${theme('colors.primary')};
		position: absolute;
		bottom: 0;
		left: 0;
	}

	@media (max-width: 991.98px) {
		padding-bottom: 0;
		margin-bottom: 10px;
		font-size: 22px;

		&:after {
			display: none;
		}
	}
`

const Description = styled.p`
	color: ${theme('colors.white', '#fff')};
	margin: 0 0 25px;

	@media (max-width: 991.98px) {
		margin-bottom: 30px;
		font-size: 14px;
	}
`

const Btn = styled.a`
	text-decoration: none;
	background-color: ${theme('colors.white', '#fff')};
	border-radius: 100px;
	font-size: 16px;
	width: 100%;
	display: block;
	padding: 12px 0;
	text-align: center;
	font-weight: 600;
	color: ${theme('colors.black33')};
	margin-bottom: 30px;

	&:hover {
		color: ${theme('colors.black33')};
		text-decoration: none;
		background-color: ${theme('colors.grayf1')};
	}

	&:focus {
		background-color: ${theme('colors.grayf1')};
	}
`

const Mask = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: 0;
`

const CarouselDestaquesMobile = ({ data }) => {
	const [img, setImg] = useState()

	const [currentIndex, setCurrentIndex] = useState(0)

	const listaDestaques = data.metadata.destaques

	// Quando o index do slick muda, a imagem de background tambem muda
	useEffect(() => {
		setImg(imgixUrlOptimizerAuto(listaDestaques[currentIndex].imagem.imgix_url))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentIndex])

	const ImagemDestaques = {
		backgroundImage: `url(${img})`
	}

	const settings = {
		className: 'center',
		centerMode: true,
		infinite: true,

		slidesToShow: 1,
		speed: 500,
		dots: true,
		initialSlide: 0,
		autoplay: true,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		],
		beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex)
	}

	return (
		<div className='slick-track'>
			<Carrossel style={ImagemDestaques}>
				<Mask />
				<Slider {...settings}>
					{listaDestaques &&
						listaDestaques.map((item, index) => (
							<CarouselItem key={`featured-mobile-carousel-item-${index}`}>
								<CarouselTxt className='carousel-txt'>
									<Subtitle>Destaques</Subtitle>
									<Title id={`title-mobile-featured-carousel-${index}`}>{item.titulo}</Title>
									<Description>{item.descricao}</Description>
									<Btn id={`button-featured-carousel-mobile-${index}`} href={item.cta_link}>
										{item.cta}
									</Btn>
								</CarouselTxt>
								<Img src={imgixUrlOptimizerAuto(item.imagem.imgix_url)} />
							</CarouselItem>
						))}
				</Slider>
			</Carrossel>
		</div>
	)
}

export default CarouselDestaquesMobile
