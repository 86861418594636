import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { HomeClientes } from '@monorepo-amais/commons/components/home'
import HomeOnly from '@monorepo-amais/commons/components/Navbar/HomeOnly'
// import SelectState from '@monorepo-amais/commons/components/SelectState/SelectState'
import { graphql } from 'gatsby'
import setMenuItens from '../components/menuList/menuList'
import { setClientMedic } from '@monorepo-amais/commons/utils/sessionControl'

export default function IndexPage({ data, location }) {
	// const [show, setShow] = React.useState(true)

	setClientMedic('cliente')

	return (
		<>
			<Layout footer={data.footer} regionais={data.regionais}>
				<SEO title='Home' fromCosmic={data.cosmicjsInformacoesEstaticas.metadata.seo} />
				{/* TODO checar se está sendo utilizado */}
				<HomeOnly
					regionais={data.regionais}
					urlResults={data.resultadoExame.metadata.link_cliente}
					contatos={data.contatos}
					contatosAgendar={data.contatosAgendar}
					menuItens={setMenuItens(true, data.resultadoExame.metadata.link_cliente)}
				/>
				<HomeClientes {...data} />
				{/* <SelectState {...data} baseLink={location.origin} show={show} setShow={setShow} /> */}
			</Layout>
		</>
	)
}

export const pageQuery = graphql`
	query MyQuery {
		modal: cosmicjsInformacoesEstaticas(title: { eq: "Regionais" }) {
			metadata {
				modal_texto
			}
		}
		contatos: cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
			metadata {
				titulo
				subtitulo
				canal_de_contato {
					icone {
						url
						imgix_url
					}
					titulo
					texto
				}
			}
		}
		contatosAgendar: cosmicjsInformacoesEstaticas(slug: { eq: "contatos-agendar" }) {
			metadata {
				titulo
				subtitulo
				horarios
				canal_de_contato {
					icone {
						url
						imgix_url
					}
					titulo
					texto
				}
			}
		}
		cosmicjsInformacoesEstaticas(title: { eq: "Home Clientes" }) {
			metadata {
				seo {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
				barra_de_servicos_titulo
				barra_de_servicos {
					titulo
					descricao
					icone {
						imgix_url
					}
				}
				seo {
					titulo
					descricao
					imagem {
						url
						imgix_url
					}
					imagem_descricao
				}
				campanha_subtitulo
				campanha_telefone
				campanha_texto
				campanha_titulo
				campanha_link
				campanha_botao
				campanha_imagem {
					url
					imgix_url
				}
				carrossel_banner {
					carrossel_banner_imagens {
						link
						imagem {
							url
							imgix_url
						}
						imagem_mobile {
							url
							imgix_url
						}
					}
				}
				destaques {
					cta
					cta_link
					descricao
					titulo
					imagem {
						url
						imgix_url
					}
				}
			}
		}
		allCosmicjsNoticias {
			nodes {
				id
				metadata {
					pequena_descricao
					seo {
						description
						image {
							imgix_url
							url
						}
						imageAlt
						title
					}
					thumbnail {
						imgix_url
						url
					}
					data
				}
				title
				slug
			}
		}
		cosmicjsNoticias {
			slug
			type_slug
			published_at
			order
			status
			title
			content
			metadata {
				thumbnail {
					imgix_url
					url
				}
				data
			}
		}
		unidades: allUnidades {
			edges {
				node {
					id
					bairro
					latitude
					longitude
					slug
					facilidades {
						id: idCosmic #alias
					}
					endereco
					nome
					idLegado
					fotos {
						imagem {
							url
							imgix_url
						}
					}
					thumbnail {
						imagem {
							url
							imgix_url
						}
					}
				}
			}
		}
		resultadoExame: cosmicjsInformacoesEstaticas(title: { eq: "Resultado de exame" }) {
			metadata {
				link_cliente
				link_medico
			}
		}
	}
`
