import React from 'react'
import styled from 'styled-components'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const BannerBackground = styled.div`
	background-image: url(${props => props.img});
	background-position: center;
	background-position-y: 25%;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 320px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	border-bottom: 5px solid ${props => props.theme.colors.serviceBar};

	@media (max-width: 767.98px) {
		height: 205px;
		background-position-y: center;
	}

	@media (max-width: 768px) {
		background-size: 100% 100%;
		background-image: url(${props => props.imgMobile});
	}
`

export default function BannerMedicos({ itens, img, imgMobile }) {
	return (
		<>
			{itens && (
				<BannerBackground img={imgixUrlOptimizerAuto(img, undefined, 320)} imgMobile={imgMobile}></BannerBackground>
			)}
		</>
	)
}
