import React from 'react'

import BannerMedicos from './BannerMedicos'
import CarouselBanner from './CarouselBanner'
import CardsMedicos from './CardsMedicos'
import InfoMedicos from './InfoMedicos'
import TrabalheConosco from './TrabalheConosco'
import HomeBoletins from './HomeBoletins'

import { MainContainer } from '../Blocks'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

export default function HomeMedicos({ cosmicjsInformacoesEstaticas, homeMedico, boletins, urlResults }) {
	const carouselBrands = ['amaissp', 'amaispr', 'amaispe', 'amaispi', 'diagnoson', 'irn', 'labs-amais']

	return (
		<>
			{carouselBrands.includes(process.env.GATSBY_COSMIC_BUCKET) ? (
				<CarouselBanner data={cosmicjsInformacoesEstaticas} />
			) : (
				<BannerMedicos
					itens={cosmicjsInformacoesEstaticas.metadata}
					img={imgixUrlOptimizerAuto(cosmicjsInformacoesEstaticas.metadata.banner.imgix_url)}
					imgMobile={imgixUrlOptimizerAuto(cosmicjsInformacoesEstaticas.metadata.banner_mobile.imgix_url)}
				/>
			)}

			<MainContainer>
				<CardsMedicos itens={cosmicjsInformacoesEstaticas.metadata} urlResults={urlResults} />

				{/* a sessao de assessoria so aparece quando ha titulo no cosmic */}
				{cosmicjsInformacoesEstaticas.metadata.titulo_assessoria_medica && (
					<InfoMedicos itens={cosmicjsInformacoesEstaticas.metadata} />
				)}

				{boletins && <HomeBoletins boletimMedico={boletins} homeMedico={homeMedico} />}

				<TrabalheConosco itens={cosmicjsInformacoesEstaticas.metadata} />
			</MainContainer>
		</>
	)
}
