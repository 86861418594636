import React from 'react'
import styled from 'styled-components'

import { prop, theme } from 'styled-tools'

import { MainTitle } from '../Typography'
import { navigate } from 'gatsby'
// import { ImgixImg } from '../Blocks'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const CardImage = styled.img`
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 220px;
	border-radius: 5px;
	margin: 12px 0;

	@media (max-width: 767.98px) {
		height: 200px;
		object-fit: cover;
	}
`

const CardsDiv = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 40px 0;

	@media (max-width: 767.98px) {
		margin: 0;
	}
`

const CardItem = styled.div`
	width: 50%;
	padding: 0 10px;

	grid-area: ${prop('area')};

	@media (max-width: 767.98px) {
		width: 100%;
		padding: 20px 15px;
	}

	/* tinha no bootstrap pra funcuionar */
	box-sizing: border-box;
`

// const CardTitle = styled.h3`
//     position: relative;
//     font-size: 26px;
//     color: ${theme('colors.black_fonts')};
//     padding-bottom: 10px;

//     &:after{
//         content: "";
//         width: 60px;
//         height: 4px;
//         background-color: ${theme('colors.primary')};
//         position: absolute;
//         bottom: 0;
//         left: 0;

//         @media(max-width:767.98px) {
//             width: 90px;
//         }
//     }
// `;

const CardTxt = styled.p`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin-block-start: 0;
	margin-block-end: 0;
	padding: 8px 0 20px;
`

const CardBtnLink = styled.a`
	font-size: 14px;
	color: ${theme('colors.white')};
	background-color: ${theme('colors.primary')};
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	padding: 20px 30px;
	border: none;
	margin: 0 0 0 auto;
	cursor: pointer;

	&:hover {
		background-color: ${theme('colors.activeHoverBlue')};
		/* border: 1px solid ${theme('colors.primary')}; */
	}

	@media (max-width: 767.98px) {
		width: 80%;

		&:focus {
			background-color: ${theme('colors.activeHoverBlue')};
			/* border: 1px solid ${theme('colors.primary')}; */
		}
	}
	text-decoration: none;
`

export default function CardsMedicos({ itens, urlResults }) {
	return (
		<>
			{itens && (
				<CardsDiv>
					<CardItem area='result'>
						<MainTitle>{itens.titulo_resultado_de_exame}</MainTitle>
						{/* <CardImage src={itens.banner.imgix_url}></CardImage> */}
						<CardImage src={imgixUrlOptimizerAuto(itens.resultado_de_exame_imagem.imgix_url)} h={220} />
						<CardTxt>{itens.resultado_de_exame}</CardTxt>

						<CardBtnLink id='button-medic-home-result-exam' href={urlResults}>
							{itens.resultado_de_exame_botao}
						</CardBtnLink>
					</CardItem>

					<CardItem>
						<MainTitle>{itens.titulo_cadastre_se}</MainTitle>
						<CardImage src={imgixUrlOptimizerAuto(itens.cadastre_se_imagem.imgix_url)} h={220} />
						<CardTxt>{itens.cadastre_se}</CardTxt>
						<CardBtnLink id='button-medic-home-register' onClick={() => navigate('/cadastro-medico/')}>
							{itens.cadastre_se_botao}
						</CardBtnLink>
					</CardItem>
				</CardsDiv>
			)}
		</>
	)
}
