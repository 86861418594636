import React from 'react'
import styled from 'styled-components'

import { theme } from 'styled-tools'
import { MainTitle } from '../Typography'
// import { ImgixImg } from '../Blocks'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const TrabalheConoscoDiv = styled.div`
	margin: 40px 0;

	@media (max-width: 767.98px) {
		padding: 0 15px;
	}

	@media (min-width: 768px) and (max-width: 1199.98px) {
		margin: 40px 10px;
	}

	grid-area: 'trab-conosco';
`

// const TrabalheConoscoTitle = styled.h3`
// 	position: relative;
// 	font-size: 26px;
// 	color: ${theme('colors.black_fonts')};
// 	padding-bottom: 10px;

// 	&:after {
// 		content: '';
// 		width: 60px;
// 		height: 4px;
// 		background-color: ${theme('colors.primary')};
// 		position: absolute;
// 		bottom: 0;
// 		left: 0;

// 		@media (max-width: 767.98px) {
// 			width: 90px;
// 		}
// 	}
// `

const TrabalheConoscoImg = styled.img`
	object-fit: cover;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 180px;
	border-radius: 5px;
	margin: 12px 0;

	@media (max-width: 767.98px) {
		height: 170px;
		background-position-x: 30%;
	}
`

const TrabalheConoscoP = styled.p`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin-block-start: 0;
	margin-block-end: 0;
	padding: 8px 0 20px;
`

const TrabalheConoscoBtn = styled.button`
	cursor: pointer;
	font-size: 14px;
	color: ${theme('colors.white')};
	background-color: ${theme('colors.primary')};
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 175px;
	height: 40px;
	border: none;
	margin: 0 0 0 auto;

	&:hover {
		background-color: ${theme('colors.activeHoverBlue')};
	}

	a {
		color: white;
		text-decoration: none;
	}

	@media (max-width: 767.98px) {
		width: 100%;

		&:focus {
			background-color: ${theme('colors.activeHoverBlue')};
		}
	}
`

export default function TrabalheConosco({ itens }) {
	return (
		<TrabalheConoscoDiv>
			<MainTitle>{itens.titulo_trabalhe_conosco}</MainTitle>
			<TrabalheConoscoImg src={imgixUrlOptimizerAuto(itens.trabalhe_conosco_imagem.imgix_url)} h={180} />
			<TrabalheConoscoP>{itens.trabalhe_conosco}</TrabalheConoscoP>
			<TrabalheConoscoBtn
				id='button-join-us'
				onClick={() => (typeof window !== 'undefined' ? window.open(itens.trabalhe_conosco_botao_link, '_blank') : '')}
			>
				{itens.trabalhe_conosco_botao}
			</TrabalheConoscoBtn>
		</TrabalheConoscoDiv>
	)
}
