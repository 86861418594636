/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-key */
import React from 'react'

import { MainContainer } from '../Blocks'

const BoletimMedico = ({ boletimMedico, homeMedico }) => {
	const boletinPage = boletimMedico.nodes
		.sort((a, b) => {
			let dateA = new Date(a.metadata.data)
			let dateB = new Date(b.metadata.data)
			return dateB - dateA
		})
		.slice(0, 1)
		.map((boletin, index) => {
			return null
			// let formatedDate = formataDataCosmic(boletin.metadata.data, true)
			// const destaques = boletin.metadata.destaques.map((destaque, index) => {
			// 	const url = `/boletim-medico/${toSlug(destaque.titulo)}`
			// 	if (index < 3) {
			// 		return (
			// 			<Link id={`bulletin-card-${toSlug(destaque.titulo)}`} to={url}>
			// 				<HighlightItem index={index}>
			// 					<HighlightThumb src={destaque.thumbnail.imgix_url} h={150} />
			// 					<HighlightTitleContainer>
			// 						<HighlightTitle>{destaque.titulo}</HighlightTitle>
			// 						<Underline />
			// 					</HighlightTitleContainer>
			// 					<HighlightText>{destaque.subtitulo}</HighlightText>
			// 				</HighlightItem>
			// 			</Link>
			// 		)
			// 	} else {
			// 		return null
			// 	}
			// })
			// return (
			// 	<BoletinContent index={index}>
			// 		<BoletinHeader>
			// 			<BoletinHeaderContent>
			// 				<BoletinHeaderText>Saiba+</BoletinHeaderText>
			// 				<Pipe></Pipe>
			// 				<BoletinHeaderText>
			// 					Edição {boletin.metadata.edicao} • ano {boletin.metadata.ano} • {formatedDate}
			// 				</BoletinHeaderText>
			// 				<HeaderPDF>
			// 					<a
			// 						id='button-download-bulettinedition'
			// 						href={boletin.metadata.pdf.url}
			// 						target='_blank'
			// 						download='proposed_file_name'
			// 					>
			// 						<HeaderPDFRigth>
			// 							<PDFIcon src={PDF}></PDFIcon>
			// 							<BoletinHeaderPDFText>Baixar em PDF</BoletinHeaderPDFText>
			// 						</HeaderPDFRigth>
			// 					</a>
			// 				</HeaderPDF>
			// 			</BoletinHeaderContent>
			// 		</BoletinHeader>
			// 		<BoletinBody>
			// 			<HighlightContainer>
			// 				<BoletinHighlight>Destaques dessa edição</BoletinHighlight>
			// 				<Underline />
			// 			</HighlightContainer>
			// 			<HighlightWrapper id='wrapper-featured-bulletins'>{destaques}</HighlightWrapper>
			// 		</BoletinBody>
			// 	</BoletinContent>
			// )
		})

	return (
		<MainContainer needPaddingZeroMax1024={false}>
			{/* <Div>
				<MainTitle>{homeMedico.metadata.titulo}</MainTitle>
				<Subtitle>
					<SubtitleText>{homeMedico.metadata.descricao}</SubtitleText>
				</Subtitle>
			</Div> */}
			{boletinPage}
			{/* <Button id='button-all-bulletins' onClick={() => navigate('/boletim-medico/')}>
				Ver todas edições
			</Button> */}
		</MainContainer>
	)
}

export default BoletimMedico
