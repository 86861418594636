import React from 'react'
import AtendimentoMovel from './AtendimentoMovel'
//import ServiceBar from './ServiceBar'
import CarouselDestaquesMobile from './CarouselDestaquesMobile'
import CarouselDestaques from './CarouselDestaques'
import CarouselBanner from './CarouselBanner'
import HomeUnidades from './HomeUnidades'
import HomeNoticias from './HomeNoticias'
import { MainContainer } from '../Blocks'
import orderByDate from '@monorepo-amais/commons/utils/orderByDate'
import styled from 'styled-components'

const Separator = styled.div`
	height: 7px;
	background-color: #edeff4;
	width: 100%;
`

export default function HomeClientes({ cosmicjsInformacoesEstaticas, allCosmicjsNoticias, unidades }) {
	const news = allCosmicjsNoticias.nodes.sort(orderByDate).slice(0, 4)

	//const hideServiceBar = cosmicjsInformacoesEstaticas.metadata.barra_de_servicos[0].titulo.length === 0
	return (
		<>
			<CarouselBanner data={cosmicjsInformacoesEstaticas} />
			<Separator />
			{/* {hideServiceBar && <Separator />}
			{hideServiceBar !== true && (
				<ServiceBar
					data={cosmicjsInformacoesEstaticas.metadata.barra_de_servicos}
					titulo={cosmicjsInformacoesEstaticas.metadata.barra_de_servicos_titulo}
				/>
			)} */}

			<MainContainer marginless={true}>
				<AtendimentoMovel campanha={cosmicjsInformacoesEstaticas} />
			</MainContainer>
			<HomeUnidades data={unidades} />
			<CarouselDestaques data={cosmicjsInformacoesEstaticas} />
			<CarouselDestaquesMobile data={cosmicjsInformacoesEstaticas} />
			<HomeNoticias data={news} />
		</>
	)
}
